<template>
  <v-dialog
    v-model="dialog"
    keydown
    persistent
    max-width="600px"
  >
    <v-card
      v-if="!infoDialog"
      min-height="500px"
    >
      <v-card-title>
        <span class="headline">{{ $t('actions|assign_documents') }}</span>
        <v-spacer />
        <v-btn
          icon
          @click="dialog = false; reset()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <div>
        <v-card-text class="py-4">
          <v-subheader>
            {{ $t('documents|selected_documents') }}
            <v-icon
              class="ml-2"
              @click="infoDialog = true"
            >
              mdi-information-variant
            </v-icon>
          </v-subheader>
          <v-card
            flat
            style="max-height: 300px; overflow-y: auto;"
            class="mb-3"
          >
            <v-list
              disabled
            >
              <v-list-item-group
                color="primary"
              >
                <v-list-item
                  v-for="(doc, i) in unassignedDocuments"
                  :key="i"
                >
                  <v-list-item-icon>
                    <v-icon>
                      mdi-file
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="doc.raw.originalname" />
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
          <v-subheader>
            {{ $t('documents|documents_excluded') }}
            <v-icon
              class="ml-2"
              @click="infoDialog = true"
            >
              mdi-information-variant
            </v-icon>
          </v-subheader>
          <v-card
            flat
            style="max-height: 300px; overflow-y: auto;"
            class="mb-3"
          >
            <v-list
              disabled
            >
              <v-list-item-group
                color="primary"
              >
                <v-list-item
                  v-for="(doc, i) in allreadyAssignedDocuments"
                  :key="i"
                  style="background-color: #EF9A9A;"
                >
                  <v-list-item-icon>
                    <v-icon>
                      mdi-file
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="doc.raw.originalname" />
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
          <v-autocomplete
            v-if="account.accountType === 'lawyer'"
            v-model="selectedClient"
            :items="clientsList"
            :label="$t('actions|select_client')"
            item-text="displayName"
            item-value="_id"
            :rules="[(v) => !!v || $t('fields|required')]"
            dense
            clearable
            outlined
          />
          <v-select
            v-if="selectedClient && account.accountType === 'lawyer'"
            v-model="selectedCases"
            :items="caseList(selectedClient)"
            :menu-props="{ bottom: true, offsetY: true }"
            :label="$t('actions|choose_case')"
            :hint="$t('common|optional')"
            persistent-hint
            outlined
            required
            dense
            item-text="caseName"
            item-value="_id"
            multiple
          />
          <div v-if="account.accountType !== 'lawyer'">
            <v-select
              v-model="selectedCaseByClient"
              :items="cases"
              item-disabled="disable"
              :menu-props="{ bottom: true, offsetY: true }"
              :label="$t('actions|choose_case')"
              :hint="$t('common|optional')"
              persistent-hint
              outlined
              required
              dense
              item-text="caseName"
              return-object
              clearable
            />
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            v-if="account.accountType === 'lawyer'"
            color="primary"
            rounded
            small
            :disabled="!selectedClient"
            min-width="150"
            :loading="loading"
            @click="assignMultipleDocuments('lawyer')"
          >
            {{ $t('actions|submit') }}
          </v-btn>
          <v-btn
            v-else
            color="primary"
            rounded
            small
            :disabled="!selectedCaseByClient"
            min-width="150"
            :loading="loading"
            @click="assignMultipleDocuments('client')"
          >
            {{ $t('actions|submit') }}
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
    <v-card
      v-if="infoDialog"
      min-height="500px"
    >
      <v-card-title>
        <span class="headline">{{ $t('actions|assign_documents') }}</span>
        <v-spacer />
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text
        class="mt-4 h5"
      >
        {{ $t('expressions|document_assign_info') }}
      </v-card-text>
      <v-card-actions>
        <v-icon
          @click="infoDialog = false"
        >
          mdi-undo
        </v-icon>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { EventBus } from '@/utils/EventBus'

export default {
  props: {
    selected: {
      type: Array,
      default: undefined
    }
  },
  data () {
    return {
      dialog: false,
      selectedClient: null,
      selectedCases: [],
      loading: false,
      error: null,
      documents: null,
      actualTab: null,
      infoDialog: false,
      selectedCaseByClient: null
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      clients: state => state.clients.clients,
      companyClients: state => state.companyClients.companyClients,
      groupClients: state => state.groupClients.groupClients,
      cases: state => state.cases.cases,
      companyCases: state => state.companyCases.companyCases,
      groupCases: state => state.groupCases.groupCases
    }),
    clientsList () {
      let _clients
      if (!this.actualTab && !this.documents) return
      if (this.actualTab.isMyTab) {
        _clients = this.clients.map((client) => {
          let displayName
          if (client.clientType === 'individual') {
            if (client.clientData.given_names) {
              displayName = `${client.clientData.given_names} ${client.clientData.surname}`
            } else {
              displayName = 'Pending Name'
            }
          } else {
            if (client.clientData.company_name) {
              displayName = client.clientData.company_name
            } else {
              displayName = 'Pending Company Name'
            }
          }
          return {
            _id: client._id,
            displayName
          }
        })
      } else if (this.actualTab.isCompanyTab) {
        _clients = this.companyClients.map((client) => {
          let displayName
          if (client.clientType === 'individual') {
            if (client.clientData.given_names) {
              displayName = `${client.clientData.given_names} ${client.clientData.surname}`
            } else {
              displayName = 'Pending Name'
            }
          } else {
            if (client.clientData.company_name) {
              displayName = client.clientData.company_name
            } else {
              displayName = 'Pending Company Name'
            }
          }
          return {
            _id: client._id,
            displayName
          }
        })
      } else if (this.actualTab.isGroupTab) {
        let _grClients = []
        this.groupClients.forEach(cl => {
          cl.sharedWith.groups.forEach(id => {
            if (id === this.actualTab.group._id) _grClients.push(cl)
          })
        })
        _clients = _grClients.map((client) => {
          let displayName
          if (client.clientType === 'individual') {
            if (client.clientData.given_names) {
              displayName = `${client.clientData.given_names} ${client.clientData.surname}`
            } else {
              displayName = 'Pending Name'
            }
          } else {
            if (client.clientData.company_name) {
              displayName = client.clientData.company_name
            } else {
              displayName = 'Pending Company Name'
            }
          }
          return {
            _id: client._id,
            displayName
          }
        })
      }
      return _clients
    },
    unassignedDocuments () {
      let docs = []
      if (this.documents) {
        this.documents.forEach(doc => {
          if (!doc.clients.length && !doc.cases.length) {
            docs.push(doc)
          }
        })
      }
      return docs
    },
    allreadyAssignedDocuments () {
      // already assigned or being precessed
      if (this.documents) {
        return this.documents.filter(doc => doc.clients.length || doc.cases.length > 0 || doc.status === 'pending')
      } else {
        return []
      }
    }
  },
  watch: {
    dialog (value) {
      if (!value) {
        this.reset()
      }
    }
  },
  created () {
    EventBus.$on('dialog-assign-document-multiple', this.onAddAssignEventMultuple)
  },
  beforeDestroy () {
    EventBus.$off('dialog-assign-document-multiple', this.onAddAssignEventMultuple)
  },
  methods: {
    ...mapActions({
      updateDocument: 'documents/updateDocument'
    }),
    onAddAssignEventMultuple (incommingDocs, tab) {
      this.documents = incommingDocs
      this.actualTab = tab
      this.dialog = true
    },
    reset () {
      this.infoDialog = false
      this.selectedClient = null
      this.selectedCases = []
      this.actualTab = null
      this.loading = false
      this.error = null
      this.documents = null
      this.selectedCaseByClient = null
    },
    caseList (clientId) {
      let availableCases = []
      if (this.cases && this.cases.length) {
        this.cases.forEach(_case => {
          if (_case.client._id === clientId) {
            availableCases.push(_case)
          }
        })
      }
      if (this.companyCases && this.companyCases.length) {
        this.companyCases.forEach(_case => {
          if (_case.client._id === clientId) {
            availableCases.push(_case)
          }
        })
      }
      if (this.groupCases && this.groupCases.length) {
        this.groupCases.forEach(_case => {
          if (_case.client._id === clientId) {
            availableCases.push(_case)
          }
        })
      }
      return availableCases
    },
    async assignMultipleDocuments (accountType) {
      this.loading = true
      try {
        if (accountType === 'client') {
          this.selectedClient = this.selectedCaseByClient.client._id
          this.selectedCases.push(this.selectedCaseByClient)
        }
        for (let i = 0; i < this.unassignedDocuments.length; i++) {
          const doc = this.unassignedDocuments[i]
          await this.updateDocument({
            _id: doc._id,
            payload: {
              clients: [this.selectedClient],
              cases: this.selectedCases,
              assignClientAndCasesToDocument: true
            }
          })
        }
      } catch (error) {
        console.error(error, 'error')
      }
      this.loading = false
      this.dialog = false
    }
  }
}
</script>
