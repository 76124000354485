<template>
  <!-- MOBILE VIEW -->
  <v-container
    v-if="$vuetify && $vuetify.breakpoint.mobile"
    class="pa-0 ma-0"
  >
    <DocumentsTableNav
      :route-elements="routeElements"
      :container-id="'NAV-TABLE'"
      :active-folder-object="activeFolderObject"
      @router-action="onRouterAction"
    />
    <!-- MOBILE TABLE -->
    <v-data-table
      v-if="!changeName"
      v-model="selected"
      :headers="tableHeaders"
      :items="documentsAndFolders"
      item-key="id"
      :search="search"
      :single-select="singleSelect"
      dense
      no-results-text
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      class="elevation-1 pt-2"
      some-items
      fixed-header
      :height="$vuetify.breakpoint.height - 260"
      @contextmenu:row="rightClickMobile"
    >
      <template v-slot:item._type="item">
        <v-icon
          v-if="item.item._type === 'folder'"
          class="mb-1 mr-1 py-4"
        >
          mdi-folder-outline
        </v-icon>
        <div v-else>
          <img
            v-if="(item.item.fileType === 'application/vnd.apple.pages' || item.item.fileType === 'application/vnd.apple.numbers') && item.item.fileType !== 'application/octet-stream'"
            :src="getSvg(item.item.fileType, item.item.raw.originalname)"
            height="24px"
            width="auto"
          >
          <img
            v-if="item.item.fileType === 'application/octet-stream' && !isIcon(item.item.fileType, item.item.raw.originalname)"
            :src="getOctetValue(item.item.raw.originalname)"
            height="24px"
            width="auto"
          >
          <v-icon
            v-if="isIcon(item.item.fileType, item.item.raw.originalname)"
            :color="getItemColor(item.item.fileType)"
          >
            {{ getIconType(item.item.fileType, item.item.raw.originalname) }}
          </v-icon>
        </div>
      </template>

      <template v-slot:item.documentType="item">
        <CustomAutocomplete
          v-if="item.item.collectionType && item.item.collectionType === 'Documents'"
          :id="item.item._id"
          :item="item.item"
          :item-text="item.item && item.item.documentType ? item.item.documentType.text : ''"
          :index="item.item.index"
          :fields="_fields"
          :used-in-documents-table="true"
          :component-is-used-in="'documentsTable'"
          @set-input-value="setInputValue"
        />
      </template>

      <template v-slot:item.cases="item">
        <v-chip
          v-for="(c, i) in item.item.cases"
          :id="item.item._id"
          :key="i"
          outlined
          small
          color="primary"
          class="ma-1"
        >
          <v-icon
            :id="item.item._id"
            class="mr-2"
            x-small
          >
            mdi-briefcase-edit
          </v-icon>
          {{ c.caseName }}
          <v-icon
            :id="item.item._id"
            class="ml-2"
            x-small
            @click="unassignDocument(item, c)"
          >
            mdi-close
          </v-icon>
        </v-chip>
      </template>

      <template v-slot:item.actions="item">
        <div
          v-if="item.item.collectionType && item.item.collectionType === 'Documents'"
          :id="item.item._id"
          class="d-flex justify-center"
        >
          <v-icon
            color="primary"
            :disabled="item.item.status === 'pending'"
            @click="openFile(item.item, 'mobile')"
          >
            mdi-file-eye
          </v-icon>

          <v-icon
            size="20"
            color="primary"
            :disabled="item.item.status === 'pending'"
            @click="updateRawData(item.item)"
          >
            mdi-pencil
          </v-icon>

          <v-menu
            offset-y
            min-width="300"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                v-bind="attrs"
                class="mr-2"
                v-on="on"
              >
                mdi-account-group
              </v-icon>
            </template>
            <v-list
              dense
              class="pa-3"
            >
              <span class="ml-2">
                {{ $t('common|uploaded_by') }}:
              </span>
              <v-list-item v-if="item.item.onCreatedBy === 'Account'">
                <v-list-item-icon>
                  <v-avatar
                    size="30px"
                    class="ml-1 mr-1"
                  >
                    <img
                      v-if="item.item.createdBy && item.item.createdBy.avatar"
                      alt="Avatar"
                      :src="`${cfg.baseURL}downloadAvatar/${item.item.createdBy._id}/${item.item.createdBy.avatar.raw.filename}`"
                    >
                    <v-avatar
                      v-else
                      color="primary"
                    >
                      <v-icon
                        color="white"
                        small
                      >
                        mdi-account
                      </v-icon>
                    </v-avatar>
                  </v-avatar>
                </v-list-item-icon>
                <span
                  class="text-truncate"
                >
                  {{ getName(item.item.createdBy) }}
                </span>
                <!-- {{ item.createdBy.accountData.accountName ? item.createdBy.accountData.accountName : account.accountName }} -->
              </v-list-item>
              <v-list-item v-if="item.item.onCreatedBy === 'Company'">
                <v-list-item-icon>
                  <v-icon
                    color="primary"
                  >
                    mdi-domain
                  </v-icon>
                </v-list-item-icon>
                {{ item.item.createdBy ? item.item.createdBy.companyData.companyName : 'not available' }} (Company)
              </v-list-item>
              <v-list-item v-if="item.item.onCreatedBy === 'Group'">
                <v-list-item-icon>
                  <v-icon
                    color="primary"
                  >
                    mdi-domain
                  </v-icon>
                </v-list-item-icon>
                {{ item.item.createdBy ? item.item.createdBy.groupName : 'not available' }} (Group)
              </v-list-item>
              <span class="ml-2">
                {{ $t('common|shared_with') }}:
              </span>
              <v-list-item
                v-for="acc in item.item.sharedWith.accounts"
                :key="acc._id"
              >
                <v-list-item-icon>
                  <v-avatar
                    size="30px"
                    class="ml-1 mr-1"
                  >
                    <img
                      v-if="acc && acc.avatar"
                      alt="Avatar"
                      :src="`${cfg.baseURL}downloadAvatar/${acc._id}/${acc.avatar.raw.filename}`"
                    >
                    <v-avatar
                      v-else
                      color="primary"
                    >
                      <v-icon
                        color="white"
                        small
                      >
                        mdi-account
                      </v-icon>
                    </v-avatar>
                  </v-avatar>
                </v-list-item-icon>
                <span
                  class="text-truncate"
                >
                  {{ getName(acc) }}
                </span>
              </v-list-item>
              <v-list-item
                v-for="c in item.item.sharedWith.company"
                :key="c._id"
              >
                <v-list-item-icon>
                  <v-icon color="primary">
                    mdi-domain
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ c.companyData.companyName }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-for="g in item.item.sharedWith.groups"
                :key="g._id"
              >
                <v-list-item-icon>
                  <v-icon color="primary">
                    mdi-account-group
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ g.groupName }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-icon
            color="primary"
            :disabled="item.item.status === 'pending'"
            @click="saveAsSingleFile(item.item)"
          >
            mdi-export
          </v-icon>

          <v-icon
            v-if="account.accountType === 'lawyer'"
            color="primary"
            :disabled="item.item.status === 'pending'"
            @click="shareItemAction(item.item, actualTab)"
          >
            mdi-share-variant
          </v-icon>

          <v-icon
            color="primary"
            :disabled="item.item.status === 'pending'"
            @click="addAssignAction(item.item)"
          >
            mdi-paperclip
          </v-icon>

          <v-icon
            v-if="(item.item.collectionType && item.item.collectionType !== 'Folders') && item.item.pinned && item.item.pinned.includes(account._id)"
            color="primary"
            :disabled="item.item.status === 'pending'"
            @click="removePinFromDashboard(item.item)"
          >
            mdi-pin
          </v-icon>
          <v-icon
            v-else
            color="primary"
            :disabled="item.item.status === 'pending'"
            @click="pinToDashboard(item.item)"
          >
            mdi-pin-outline
          </v-icon>

          <v-icon
            color="primary"
            @click="deleteDocumentAction(item.item)"
          >
            mdi-delete
          </v-icon>
        </div>
      </template>
    </v-data-table>

    <ChangeName
      v-else
      :item="selectedDocForNameChange"
      :original-name="selectedDocForNameChange.raw.originalname"
      @update-data="updateRawData"
      @close="changeName = false"
    />

    <v-toolbar
      flat
      color="primary"
      class="bottom-element-documents-mobile"
    >
      <v-col cols="8">
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          :label="$t('actions|search_documents')"
          dense
          dark
          hide-details
        />
      </v-col>
      <v-btn
        v-if="!actualTab.isSharedWithMe"
        icon
        dark
        :disabled="!activeButton(account, company, group, actualTab)"
        @click="addDocumentAction(actualTab, activeFolderObject)"
      >
        <v-icon>
          mdi-upload
        </v-icon>
      </v-btn>
      <v-btn
        v-if="!actualTab.isSharedWithMe"
        icon
        dark
        @click="createFolder()"
      >
        <v-icon>
          mdi-folder-plus-outline
        </v-icon>
      </v-btn>
      <v-btn
        outlined
        small
        rounded
        color="primary"
        class="ml-4 mr-2"
        @click="addExportAction(filteredDocuments)"
      >
        <v-icon
          small
          class="mr-1"
        >
          mdi-export
        </v-icon>
        {{ $t('actions|export') }}
      </v-btn>
    </v-toolbar>

    <FileView
      ref="fileView"
    />
  </v-container>

  <!-- DESKTOP VIEW -->
  <v-container
    v-else
    fluid
    class="pa-0"
    :style="`max-height: ${($vuetify.breakpoint.height - 130).toString() + 'px'};`"
  >
    <v-container
      fluid
      class="px-3 mt-3"
      style="position: sticky; top: 0px; z-index: 1;"
    >
      <v-row
        class="mt-n6"
        justify="start"
        align="center"
      >
        <v-toolbar
          flat
          color="lightBackground"
        >
          <v-col cols="3">
            <v-text-field
              v-model="search"
              :solo-inverted="soloInverted"
              :solo="solo"
              prepend-inner-icon="mdi-magnify"
              rounded
              :label="$t('actions|search_documents')"
              dense
              class="shrink"
              hide-details
              @focus="soloInverted = true; solo = false"
              @blur="soloInverted = false; solo = true"
            />
          </v-col>
          <v-spacer />
          <DocumentsTableNav
            :route-elements="routeElements"
            :container-id="'NAV-TABLE'"
            :active-folder-object="activeFolderObject"
            @router-action="onRouterAction"
          />
          <v-btn
            v-if="!actualTab.isSharedWithMe"
            color="primary"
            rounded
            small
            :disabled="!activeButton(account, company, group, actualTab)"
            class="mx-2 pl-4"
            @click="addDocumentAction(actualTab, activeFolderObject)"
          >
            <v-icon
              small
              class="mr-1"
            >
              mdi-upload
            </v-icon>
            {{ $t('actions|upload') }}
          </v-btn>
          <v-btn
            v-if="!actualTab.isSharedWithMe"
            color="primary"
            rounded
            small
            class="mx-2 pl-4"
            @click="createFolder()"
          >
            {{ $t('folders|create_folder') }}
          </v-btn>
          <v-btn
            outlined
            small
            rounded
            color="primary"
            class="ml-4 mr-2"
            @click="addExportAction(filteredDocuments)"
          >
            <v-icon
              small
              class="mr-1"
            >
              mdi-export
            </v-icon>
            {{ $t('actions|export') }}
          </v-btn>
        </v-toolbar>
      </v-row>
    </v-container>
    <v-card
      :key="refreshDocumentsKey"
      class="documents-table-wrapper"
    >
      <!-- <v-menu
        v-if="showRightClickMenu"
        v-model="showRightClickMenu"
        offset-y
        :position-x="menuPositionX"
        :position-y="menuPositionY"
        @input="closeMenu()"
      >
        <v-list>
          <v-list-item-group>
            <v-list-item
              v-for="(item, i) in rightClickMenuItems"
              :key="i"
              :disabled="checkDisabledStatus(item)"
              @click="fireActionEvent(item)"
            >
              <v-list-item-icon>
                <v-icon>
                  {{ item.icon }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu> -->

      <!-- @contextmenu:row="rightClickHandler" v-slot:item="item" needed to work-->
      <v-data-table
        v-model="selected"
        :headers="tableHeaders"
        :items="documentsAndFolders"
        :search="search"
        :single-select="singleSelect"
        item-key="_id"
        show-select
        dense
        no-results-text
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        class="elevation-1 pt-2"
        some-items
        fixed-header
        :height="selected.length ? ($vuetify.breakpoint.height - 298).toString() + 'px' : ($vuetify.breakpoint.height - 260).toString() + 'px'"
      >
        <template v-slot:top>
          <v-row
            v-if="selected.length"
            align="end"
            no-gutters
            class="ml-3 mb-1"
          >
            <template>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-btn
                      icon
                      color="primary"
                      :loading="loading"
                      :disabled="getStatus(selected)"
                      @click="saveAsMultipleFiles"
                    >
                      <v-icon>
                        mdi-export
                      </v-icon>
                    </v-btn>
                  </span>
                </template>
                <span v-if="!!getStatus(selected)">{{ $t('expressions|currently_processing_doc') }}</span>
                <span v-else>{{ $t('actions|export') }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-btn
                      v-if="account.accountType === 'lawyer'"
                      icon
                      color="primary"
                      :disabled="getStatus(selected)"
                      @click="shareMultipleDocs(selected, actualTab)"
                    >
                      <v-icon>
                        mdi-share-variant
                      </v-icon>
                    </v-btn>
                  </span>
                </template>
                <span v-if="!!getStatus(selected)">{{ $t('expressions|currently_processing_doc') }}</span>
                <span v-else>{{ $t('actions|share') }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-btn
                      icon
                      color="primary"
                      :disabled="getStatus(selected)"
                      @click="addAssignActionMultiple"
                    >
                      <v-icon>
                        mdi-paperclip
                      </v-icon>
                    </v-btn>
                  </span>
                </template>
                <span v-if="!!getStatus(selected)">{{ $t('expressions|currently_processing_doc') }}</span>
                <span v-else>{{ $t('actions|assign') }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    color="primary"
                    v-on="on"
                    @click="deleteMultipleDocuments"
                  >
                    <v-icon>
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('actions|delete') }}</span>
              </v-tooltip>
            </template>
          </v-row>
        </template>

        <template v-slot:body="props">
          <!-- <template v-slot:item="item"> -->
          <tbody
            v-for="(itm, idx) in props.items.filter(item => item.collectionType === 'Folders')"
            :key="itm._id"
          >
            <draggable
              :id="itm._id"
              v-model="filteredFolders[idx].documents"
              tag="tr"
              draggable=".doc"
              :group="{ name: itm._id, pull: false, put: true }"
              class="folder-list-group"
              :class="dragArea.includes(itm._id) ? 'active-folder' : ''"
              @input="event => emitter(event)"
            >
              <td
                :id="itm._id"
                @mouseover="hoverRow(itm)"
                @mouseleave="clearHover()"
              >
                <!-- <v-checkbox
                  :id="itm._id"
                  v-model="selected[idx]"
                /> -->
              </td>
              <td
                :id="itm._id"
                @mouseover="hoverRow(itm)"
                @mouseleave="clearHover()"
              >
                <v-icon
                  :id="itm._id"
                  class="mb-1 mr-1 py-4"
                >
                  mdi-folder-outline
                </v-icon>
                <span
                  :id="itm._id"
                  style="cursor: pointer;"
                  @click="folderRouting(itm)"
                >
                  {{ itm.raw.originalname }}
                </span>
              </td>
              <td
                :id="itm._id"
                @mouseover="hoverRow(itm)"
                @mouseleave="clearHover()"
              >
                <v-row>
                  <v-col cols="12">
                    <div />
                  </v-col>
                </v-row>
              </td>
              <td
                :id="itm._id"
                @mouseover="hoverRow(itm)"
                @mouseleave="clearHover()"
              >
                <v-row>
                  <v-col cols="12">
                    <div />
                  </v-col>
                </v-row>
              </td>
              <td
                :id="itm._id"
                @mouseover="hoverRow(itm)"
                @mouseleave="clearHover()"
              >
                <v-row>
                  <v-col cols="12">
                    <div />
                  </v-col>
                </v-row>
              </td>
              <td
                :id="itm._id"
                @mouseover="hoverRow(itm)"
                @mouseleave="clearHover()"
              >
                <v-row>
                  <v-col cols="12">
                    <div />
                  </v-col>
                </v-row>
              </td>
              <td
                :id="itm._id"
                @mouseover="hoverRow(itm)"
                @mouseleave="clearHover()"
              >
                <v-row>
                  <v-col cols="12">
                    <div />
                  </v-col>
                </v-row>
              </td>
              <td
                :id="itm._id"
                @mouseover="hoverRow(itm)"
                @mouseleave="clearHover()"
              >
                <v-row>
                  <v-col cols="12">
                    <div />
                  </v-col>
                </v-row>
              </td>
              <td
                :id="itm._id"
                @mouseover="hoverRow(itm)"
                @mouseleave="clearHover()"
              >
                <div
                  v-if="hoveredDocumentRow === itm"
                  :id="itm._id"
                  class="d-flex justify-center"
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon
                          v-bind="attrs"
                          color="primary"
                          v-on="on"
                          @click="openFolder(itm)"
                        >
                          {{ icons.openFolder }}
                        </v-icon>
                      </span>
                    </template>
                    <span>{{ $t('actions|open') }}</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon
                          size="20"
                          color="primary"
                          @click="changeFolderName(itm)"
                        >
                          mdi-pencil
                        </v-icon>
                      </span>
                    </template>
                    <span>{{ $t('actions|change_name') }}</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon
                          v-if="account.accountType === 'lawyer'"
                          color="primary"
                          @click="onShareFolder(itm)"
                        >
                          mdi-share-variant
                        </v-icon>
                      </span>
                    </template>
                    <span>{{ $t('actions|share') }}</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon
                          color="primary"
                          @click="onExportFolder(itm)"
                        >
                          mdi-export
                        </v-icon>
                      </span>
                    </template>
                    <span>{{ $t('actions|export') }}</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        color="primary"
                        v-on="on"
                        @click="onDeleteFolder(itm)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <span>{{ $t('actions|delete') }}</span>
                  </v-tooltip>
                </div>
              </td>
            </draggable>
            <v-divider />
          </tbody>

          <draggable
            id="docTableDocs"
            :list="filteredDocuments"
            tag="tbody"
            draggable=".doc"
            :group="{ name: 'docTableDocs', pull: true, put: true }"
            class="document-list-group"
            drag-class="ghost"
            handle=".handle"
            :move="onMoveCallback"
            @end="onDropCallback"
            @start="onDragStart"
          >
            <tr
              v-for="(item, index) in props.items.filter(item => item.collectionType !== 'Folders')"
              :id="item._id"
              :key="index"
              :class="item.collectionType && item.collectionType === 'Folders' ? '' : 'doc'"
              @mouseover="hoverRow(item)"
              @mouseleave="clearHover()"
            >
              <td :id="item._id">
                <v-checkbox
                  :id="item._id"
                  :input-value="getCheckboxVal(filteredDocuments[index])"
                  @click="toggleDocument(filteredDocuments[index])"
                />
              </td>
              <td
                v-if="item.collectionType && item.collectionType === 'Documents'"
                :id="item._id"
              >
                <v-container
                  :id="item._id"
                  class="ml-n4"
                  style="width: 300px;"
                >
                  <v-row
                    :id="item._id"
                    justify="start"
                    align="center"
                  >
                    <v-col
                      :id="item._id"
                      cols="1"
                    >
                      <v-hover
                        :id="item._id"
                        v-slot="{ hover }"
                      >
                        <div
                          :id="item._id"
                        >
                          <v-icon
                            v-if="isIcon(item.fileType, item.raw.originalname) && !hover"
                            :id="item._id"
                            :color="getItemColor(item.fileType)"
                          >
                            {{ getIconType(item.fileType, item.raw.originalname) }}
                          </v-icon>
                          <v-icon
                            v-if="hover"
                            :id="item._id"
                            class="handle"
                          >
                            mdi-cursor-move
                          </v-icon>
                        </div>
                      </v-hover>
                    </v-col>
                    <v-col
                      :id="item._id"
                      cols="11"
                      class="px-4"
                    >
                      <img
                        v-if="(item.fileType === 'application/vnd.apple.pages' || item.fileType === 'application/vnd.apple.numbers') && item.fileType !== 'application/octet-stream'"
                        :id="item._id"
                        :src="getSvg(item.fileType, item.raw.originalname)"
                        height="24px"
                        width="auto"
                      >
                      <img
                        v-if="item.fileType === 'application/octet-stream' && !isIcon(item.fileType, item.raw.originalname)"
                        :id="item._id"
                        :src="getOctetValue(item.raw.originalname)"
                        height="24px"
                        width="auto"
                      >
                      <span
                        :id="item._id"
                        style="cursor: pointer;"
                        @click="openFile(item)"
                      >
                        {{ item.raw.originalname }}
                      </span>
                    </v-col>
                  </v-row>
                </v-container>
              </td>
              <td :id="item._id">
                <v-row
                  v-if="item.collectionType && item.collectionType === 'Documents'"
                  :id="item._id"
                >
                  <v-col
                    :id="item._id"
                    cols="12"
                  >
                    <div
                      :id="item._id"
                    >
                      {{ formatBytes(item.size) }}
                    </div>
                  </v-col>
                </v-row>
              </td>
              <td>
                <v-row
                  v-if="item.collectionType && item.collectionType === 'Documents'"
                  :id="item._id"
                >
                  <v-col
                    :id="item._id"
                    cols="12"
                  >
                    <div
                      v-if="item.status === 'pending'"
                      :id="item._id"
                    >
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-progress-circular
                            v-if="item.status === 'pending'"
                            indeterminate
                            size="22"
                            :width="3"
                            color="accent"
                            v-bind="attrs"
                            v-on="on"
                          />
                        </template>
                        <span>{{ $t('documents|processing_document') }}</span>
                      </v-tooltip>
                    </div>
                    <div
                      v-if="item.status === 'error'"
                      :id="item._id"
                    >
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            color="error"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-alert
                          </v-icon>
                        </template>
                        <span>{{ $t('documents|processing_error') }}</span>
                      </v-tooltip>
                    </div>
                    <div
                      v-if="item.status === 'processed'"
                      :id="item._id"
                    >
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-if="item.status === 'processed'"
                            color="success"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-check-circle
                          </v-icon>
                        </template>
                        <span>{{ $t('documents|processed_successfully') }}</span>
                      </v-tooltip>
                    </div>
                  </v-col>
                </v-row>
              </td>
              <td :id="item._id">
                <CustomAutocomplete
                  v-if="item.collectionType && item.collectionType === 'Documents'"
                  :id="item._id"
                  :item="item"
                  :item-text="item.documentType.text"
                  :index="index"
                  :fields="_fields"
                  :used-in-documents-table="true"
                  :component-is-used-in="'documentsTable'"
                  @set-input-value="setInputValue"
                />
              </td>
              <td :id="item._id">
                <v-chip
                  v-if="item.collectionType && item.collectionType === 'Documents' && item.clientName"
                  :id="item._id"
                  outlined
                  small
                  color="primary"
                >
                  <v-icon
                    :id="item._id"
                    class="mr-2"
                    x-small
                  >
                    mdi-account
                  </v-icon>
                  {{ item.clientName }}
                  <v-icon
                    :id="item._id"
                    x-small
                    class="ml-2"
                    @click="unassignDocumentClient(item, item.clientName)"
                  >
                    mdi-close
                  </v-icon>
                </v-chip>
              </td>
              <td>
                <div
                  v-if="item.collectionType && item.collectionType === 'Documents'"
                  :id="item._id"
                >
                  <v-chip
                    v-for="(c, i) in item.cases"
                    :id="item._id"
                    :key="i"
                    outlined
                    small
                    color="primary"
                    class="ma-1"
                  >
                    <v-icon
                      :id="item._id"
                      class="mr-2"
                      x-small
                    >
                      mdi-briefcase-edit
                    </v-icon>
                    {{ c.caseName }}
                    <v-icon
                      :id="item._id"
                      class="ml-2"
                      x-small
                      @click="unassignDocument(item, c)"
                    >
                      mdi-close
                    </v-icon>
                  </v-chip>
                </div>
              </td>
              <td :id="item._id">
                <div :id="item._id">
                  {{ item.createdAt }}
                </div>
              </td>
              <td :id="item._id">
                <div
                  v-if="item.collectionType && item.collectionType === 'Documents' && hoveredDocumentRow === item"
                  :id="item._id"
                  class="d-flex justify-center"
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon
                          v-bind="attrs"
                          color="primary"
                          :disabled="item.status === 'pending'"
                          v-on="on"
                          @click="openFile(item)"
                        >
                          mdi-file-eye
                        </v-icon>
                      </span>
                    </template>
                    <span v-if="item.status === 'pending'">{{ $t('expressions|currently_processing_doc') }}</span>
                    <span v-else>{{ $t('actions|open') }}</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon
                          size="20"
                          color="primary"
                          :disabled="item.status === 'pending'"
                          @click="updateRawData(item)"
                        >
                          mdi-pencil
                        </v-icon>
                      </span>
                    </template>
                    <span v-if="item.status === 'pending'">{{ $t('expressions|currently_processing_doc') }}</span>
                    <span v-else>{{ $t('actions|change_document_name') }}</span>
                  </v-tooltip>

                  <v-menu
                    offset-y
                    min-width="300"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        v-bind="attrs"
                        class="mr-2"
                        v-on="on"
                      >
                        mdi-account-group
                      </v-icon>
                    </template>
                    <v-list
                      dense
                      class="pa-3"
                    >
                      <span class="ml-2">
                        {{ $t('common|uploaded_by') }}:
                      </span>
                      <v-list-item v-if="item.onCreatedBy === 'Account'">
                        <v-list-item-icon>
                          <v-avatar
                            size="30px"
                            class="ml-1 mr-1"
                          >
                            <img
                              v-if="item.createdBy && item.createdBy.avatar"
                              alt="Avatar"
                              :src="`${cfg.baseURL}downloadAvatar/${item.createdBy._id}/${item.createdBy.avatar.raw.filename}`"
                            >
                            <v-avatar
                              v-else
                              color="primary"
                            >
                              <v-icon
                                color="white"
                                small
                              >
                                mdi-account
                              </v-icon>
                            </v-avatar>
                          </v-avatar>
                        </v-list-item-icon>
                        <span
                          class="text-truncate"
                        >
                          {{ getName(item.createdBy) }}
                        </span>
                        <!-- {{ item.createdBy.accountData.accountName ? item.createdBy.accountData.accountName : account.accountName }} -->
                      </v-list-item>
                      <v-list-item v-if="item.onCreatedBy === 'Company'">
                        <v-list-item-icon>
                          <v-icon
                            color="primary"
                          >
                            mdi-domain
                          </v-icon>
                        </v-list-item-icon>
                        {{ item.createdBy ? item.createdBy.companyData.companyName : 'not available' }} (Company)
                      </v-list-item>
                      <v-list-item v-if="item.onCreatedBy === 'Group'">
                        <v-list-item-icon>
                          <v-icon
                            color="primary"
                          >
                            mdi-domain
                          </v-icon>
                        </v-list-item-icon>
                        {{ item.createdBy ? item.createdBy.groupName : 'not available' }} (Group)
                      </v-list-item>
                      <span class="ml-2">
                        {{ $t('common|shared_with') }}:
                      </span>
                      <v-list-item
                        v-for="acc in item.sharedWith.accounts"
                        :key="acc._id"
                      >
                        <v-list-item-icon>
                          <v-avatar
                            size="30px"
                            class="ml-1 mr-1"
                          >
                            <img
                              v-if="acc && acc.avatar"
                              alt="Avatar"
                              :src="`${cfg.baseURL}downloadAvatar/${acc._id}/${acc.avatar.raw.filename}`"
                            >
                            <v-avatar
                              v-else
                              color="primary"
                            >
                              <v-icon
                                color="white"
                                small
                              >
                                mdi-account
                              </v-icon>
                            </v-avatar>
                          </v-avatar>
                        </v-list-item-icon>
                        <span
                          class="text-truncate"
                        >
                          {{ getName(acc) }}
                        </span>
                      </v-list-item>
                      <v-list-item
                        v-for="c in item.sharedWith.company"
                        :key="c._id"
                      >
                        <v-list-item-icon>
                          <v-icon color="primary">
                            mdi-domain
                          </v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ c.companyData.companyName }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        v-for="g in item.sharedWith.groups"
                        :key="g._id"
                      >
                        <v-list-item-icon>
                          <v-icon color="primary">
                            mdi-account-group
                          </v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ g.groupName }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon
                          color="primary"
                          :disabled="item.status === 'pending'"
                          @click="saveAsSingleFile(item)"
                        >
                          mdi-export
                        </v-icon>
                      </span>
                    </template>
                    <span v-if="item.status === 'pending'">{{ $t('expressions|currently_processing_doc') }}.</span>
                    <span v-else>{{ $t('actions|export') }}</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon
                          v-if="account.accountType === 'lawyer'"
                          color="primary"
                          :disabled="item.status === 'pending'"
                          @click="shareItemAction(item, actualTab)"
                        >
                          mdi-share-variant
                        </v-icon>
                      </span>
                    </template>
                    <span v-if="item.status === 'pending'">{{ $t('expressions|currently_processing_doc') }}.</span>
                    <span v-else>{{ $t('actions|share') }}</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon
                          color="primary"
                          :disabled="item.status === 'pending'"
                          @click="addAssignAction(item)"
                        >
                          mdi-paperclip
                        </v-icon>
                      </span>
                    </template>
                    <span v-if="item.status === 'pending'">{{ $t('expressions|currently_processing_doc') }}</span>
                    <span v-else>{{ $t('actions|assign') }}</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon
                          v-if="(item.collectionType && item.collectionType !== 'Folders') && item.pinned && item.pinned.includes(account._id)"
                          color="primary"
                          :disabled="item.status === 'pending'"
                          @click="removePinFromDashboard(item)"
                        >
                          mdi-pin
                        </v-icon>
                        <v-icon
                          v-else
                          color="primary"
                          :disabled="item.status === 'pending'"
                          @click="pinToDashboard(item)"
                        >
                          mdi-pin-outline
                        </v-icon>
                      </span>
                    </template>
                    <span v-if="item.status && item.status === 'pending'">{{ $t('expressions|currently_processing_doc') }}</span>
                    <span v-if="item.status && item.status !== 'pending' && item.pinned.includes(account._id)">{{ $t('actions|remove_from_pinboard') }}</span>
                    <span v-if="item.status && item.status !== 'pending' && !item.pinned.includes(account._id)">{{ $t('actions|add_to_pinboard') }}</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        color="primary"
                        v-on="on"
                        @click="deleteDocumentAction(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <span>{{ $t('actions|delete') }}</span>
                  </v-tooltip>
                </div>
              </td>
            </tr>
          </draggable>
        </template>
      </v-data-table>
    </v-card>
    <AssignMultipleDocuments
      :selected="selected"
    />
    <FileView
      ref="fileView"
    />
    <DocumentMoveDialog
      ref="moveDialog"
      :route-elements="routeElements"
      :actual-tab="actualTab"
    />
    <ShareFolders
      ref="shareFolders"
    />
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import {
  mdiChevronDown,
  mdiCog,
  mdiFolderEditOutline,
  mdiFolderEyeOutline,
  mdiFolderArrowRightOutline,
  mdiShareVariantOutline,
  mdiFolderArrowDownOutline
} from '@mdi/js'
import generalMixin from '@/utils/generalMixin'
import DocumentMixin from './DocumentMixins/DocumentMixin'
import moment from 'moment'
import cfg from '@/config'
import FileView from '../../components/dialogs/FileView.vue'
import AssignMultipleDocuments from '../../components/dialogs/AssignMultipleDocuments.vue'
import CustomAutocomplete from '../../components/InputFields/CustomAutocomplete.vue'
import draggable from 'vuedraggable'
import DocumentsTableNav from './DocumentsTableNav.vue'
import DocumentMoveDialog from '../../components/dialogs/DocumentMoveDialog.vue'
import ShareFolders from '../../components/dialogs/ShareFolders.vue'
import ChangeName from '../Mobile/Components/ChangeName.vue'
const createdAtFormat = date => moment(date).format('YYYY-MM-DD')

export default {
  components: {
    FileView,
    AssignMultipleDocuments,
    CustomAutocomplete,
    draggable,
    DocumentsTableNav,
    DocumentMoveDialog,
    ShareFolders,
    ChangeName
  },
  mixins: [generalMixin, DocumentMixin],
  props: {
    group: {
      type: Object,
      default: null
    },
    company: {
      type: Object,
      default: null
    },
    isCompanyTab: {
      type: Boolean,
      default: false
    },
    isGroupTab: {
      type: Boolean,
      default: false
    },
    isMyTab: {
      type: Boolean,
      default: false
    },
    isLavviraTab: {
      type: Boolean,
      default: false
    },
    isSingleAcc: {
      type: Boolean,
      default: false
    },
    isSharedWithMe: {
      type: Boolean,
      default: false
    },
    userRole: {
      type: String,
      default: undefined
    },
    actualTab: {
      type: Object,
      default: null
    },
    tabChanged: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      cfg,
      loading: false,
      refreshDocumentsKey: 0,
      dialog: false,
      dialogDelete: false,
      sortBy: 'createdAt',
      sortDesc: true,
      singleSelect: false,
      selected: [],
      search: '',
      soloInverted: false,
      solo: true,
      selectedItem: '',
      icons: {
        arrowDown: mdiChevronDown,
        cog: mdiCog,
        editFolder: mdiFolderEditOutline,
        openFolder: mdiFolderEyeOutline,
        moveFolder: mdiFolderArrowRightOutline,
        share: mdiShareVariantOutline,
        exportFolder: mdiFolderArrowDownOutline
      },
      innerDocuments: [],
      documentClient: null,
      documentCases: [],
      selectedLanguage: null,
      showRightClickMenu: false,
      menuPositionX: null,
      menuPositionY: null,
      actualRightClickedRow: null,
      menuSelection: null,
      inputFocus: null,
      query: null,
      dragArea: [],
      hoveredDocumentRow: false,
      contextMenuId: null,
      changeName: false,
      selectedDocForNameChange: null
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      clients: state => state.clients.clients,
      documents: state => state.documents.documents,
      sharedWithMeDocuments: state => state.documents.sharedWithMeDocuments,
      companyDocuments: state => state.companyDocuments.companyDocuments,
      groupDocuments: state => state.groupDocuments.groupDocuments,
      folders: state => state.documents.folders,
      sharedWithMeFolders: state => state.documents.sharedWithMeFolders,
      companyFolders: state => state.companyDocuments.companyFolders,
      groupFolders: state => state.groupDocuments.groupFolders,
      settings: state => state.settings.settings
    }),
    activeFolder () {
      let _activeFolder = null
      const splittedRoute = this.$route.path.split('/')
      if (splittedRoute[3]) _activeFolder = splittedRoute[3]
      return _activeFolder
    },
    activeFolderObject () {
      if (!this.activeFolder) {
        return null
      } else {
        const activeFolder = this.folders.find(f => f._id === this.activeFolder) ||
        this.sharedWithMeFolders.find(f => f._id === this.activeFolder) ||
        this.companyFolders.find(f => f._id === this.activeFolder) ||
        this.groupFolders.find(f => f._id === this.activeFolder)
        return activeFolder
      }
    },
    routeElements () {
      let elements = [{ text: 'Main', _id: null }]
      if (this.activeFolder) {
        elements = []
        elements.push({ text: this.activeFolderObject.raw.originalname, _id: this.activeFolderObject._id })
        const getAllParentFolders = (folId) => {
          if (folId) {
            const foundParentFolder =
            this.folders.find(f => f._id === folId) ||
            this.companyFolders.find(f => f._id === folId) ||
            this.groupFolders.find(f => f._id === folId)
            elements.push({ text: foundParentFolder.raw.originalname, _id: foundParentFolder._id })
            getAllParentFolders(foundParentFolder.belongsTo)
          }
        }
        getAllParentFolders(this.activeFolderObject.belongsTo)
      }
      if (this.activeFolder) elements.push({ text: 'Main', _id: null })
      const res = elements.reverse()
      this.$emit('route-elements', res)
      return res
    },
    _input () {
      return {
        'color': '#234e79',
        'font-size': '12px',
        paddingRight: '30px',
        paddingLeft: '8px',
        paddingTop: '4px',
        paddingBottom: '4px',
        'text-align': 'start',
        'width': '180px',
        'outline-width': '1px',
        'outline-style': 'solid',
        'outline-color': '#234e79',
        'border-radius': '25px'
      }
    },
    _inputFocus () {
      return {
        color: '#234e79',
        fontSize: '12px',
        paddingRight: '30px',
        paddingLeft: '25px',
        paddingTop: '4px',
        paddingBottom: '4px',
        width: '180px',
        'text-align': 'start',
        'outline-width': '1px',
        'outline-style': 'solid',
        'outline-color': '#234e79',
        'border-radius': '25px'
      }
    },
    documentSuggestions () {
      const suggestions = Object.keys(this.settings.fields).reduce((acc, key) => {
        const item = this.settings.fields[key]
        if (item.type === 'file-input') {
          acc[key] = item
        }
        return acc
      }, {})
      return suggestions
    },
    _fields () {
      let _filtered
      const suggestions = Object.keys(this.documentSuggestions).map((key) => {
        let name = this.documentSuggestions[key].label[this.selectedLanguage] ?? undefined
        if (Array.isArray(this.documentSuggestions[key].label[this.selectedLanguage])) {
          name = this.documentSuggestions[key].label[this.selectedLanguage].join(' / ')
        }
        return {
          name,
          fieldKey: key,
          language: this.selectedLanguage
        }
      })
      // remove suggestions with no label in this actual language
      const removeUndefined = (obj) => {
        for (var prop in obj) {
          if (obj.hasOwnProperty(prop) && obj[prop] === undefined) {
            return
          }
        }
        return obj
      }
      _filtered = suggestions.filter(removeUndefined)
      if (!_filtered.length) _filtered.push({ name: this.$t('common|no_results_found') })
      return _filtered
    },
    filteredFields () {
      if (!this.query) return this._fields
      return this._fields.filter(field => {
        if (field.name.toLowerCase().includes(this.query.toLowerCase())) return field
      })
    },
    filteredDocumentsByPermissions () {
      let actualDocuments = []
      if (this.isSingleAcc && !this.isLavviraTab && !this.isSharedWithMe) {
        if (this.documents && this.documents.length) {
          actualDocuments = this.documents
        }
      }
      if (this.isMyTab && !this.isCompanyTab && !this.isGroupTab && !this.isLavviraTab && !this.isSingleAcc && this.userRole !== 'regular') {
        if (this.documents && this.documents.length) {
          actualDocuments = this.documents
        }
      }
      if (this.isCompanyTab && this.company) {
        actualDocuments = this.companyDocuments
      }
      if (this.isGroupTab && this.group) {
        for (let i = 0; i < this.groupDocuments.length; i++) {
          const groupDoc = this.groupDocuments[i]
          groupDoc.sharedWith.groups.forEach(group => {
            if (this.group._id === group._id) {
              actualDocuments.push(groupDoc)
            }
          })
        }
      }
      if (this.isSharedWithMe) {
        const myAccountId = this.account._id
        actualDocuments = this.sharedWithMeDocuments
        return actualDocuments.filter(function (doc) {
          const is = (typeof doc.createdBy)
          if (is === 'string') {
            return doc.createdBy !== myAccountId
          } else {
            return doc.createdBy._id !== myAccountId
          }
        })
      }
      return actualDocuments || []
    },
    filteredDocuments () {
      if (!this.activeFolder) {
        return this.filteredDocumentsByPermissions.map((doc, index) => {
          const client = doc.clients[0]
          let clientName = ''
          if (client && client.clientData) {
            clientName = client && client.clientType === 'individual'
              ? client.clientData.given_names + ' ' + client.clientData.surname
              : client.clientData.company_name
          }
          return {
            ...doc,
            updatedAt: createdAtFormat(doc.updatedAt),
            createdAt: createdAtFormat(doc.createdAt),
            fileType: doc.raw.mimetype,
            documentType: {
              key: doc.info.document_type,
              text: this.getFieldValue(doc.info.document_type, this.selectedLanguage)
            // getFieldValue => in generalMixin/utils available
            },
            clientName,
            index: index,
            size: doc.raw.size,
            docTypeMenuActive: false,
            _type: 'document',
            id: doc._id
          }
        }).filter(item => !item.belongsToFolder)
      } else {
        if (this.activeFolderObject && this.activeFolderObject.documents && this.activeFolderObject.documents.length) {
          return this.activeFolderObject.documents.map((doc, index) => {
            const client = doc.clients[0]
            let clientName = ''
            if (client && client.clientData) {
              clientName = client && client.clientType === 'individual'
                ? client.clientData.given_names + ' ' + client.clientData.surname
                : client.clientData.company_name
            }
            return {
              ...doc,
              updatedAt: createdAtFormat(doc.updatedAt),
              createdAt: createdAtFormat(doc.createdAt),
              fileType: doc.raw.mimetype,
              documentType: {
                key: doc.info.document_type,
                text: this.getFieldValue(doc.info.document_type, this.selectedLanguage)
                // getFieldValue => in generalMixin/utils available
              },
              clientName,
              index: index,
              size: doc.raw.size,
              docTypeMenuActive: false,
              _type: 'document',
              id: doc._id
            }
          })
        } else return []
      }
    },
    filteredFoldersByPermissions () {
      let actualFolders = []
      if (this.isSingleAcc || this.isMyTab) {
        if (this.folders && this.folders.length) {
          actualFolders = this.folders
        }
      }
      // if (this.isMyTab && !this.isCompanyTab && !this.isGroupTab && !this.isLavviraTab && !this.isSingleAcc && this.userRole !== 'regular') {
      //   if (this.folders && this.folders.length) {
      //     actualFolders = this.folders
      //   }
      // }
      if (this.isCompanyTab && this.company) {
        actualFolders = this.companyFolders
      }
      if (this.isGroupTab && this.group) {
        for (let i = 0; i < this.groupFolders.length; i++) {
          const groupFol = this.groupFolders[i]
          groupFol.sharedWith.groups.forEach(grId => {
            if (this.group._id === grId) {
              actualFolders.push(groupFol)
            }
          })
        }
      }
      if (this.isSharedWithMe) {
        const myAccountId = this.account._id
        actualFolders = this.sharedWithMeFolders
        return actualFolders.filter(function (fol) {
          const is = (typeof fol.createdBy)
          if (is === 'string') {
            return fol.createdBy !== myAccountId
          } else {
            return fol.createdBy._id !== myAccountId
          }
        })
      }
      return actualFolders
    },
    filteredFolders () {
      if (!this.activeFolder) {
        return this.filteredFoldersByPermissions.map((folder) => {
          return {
            ...folder,
            _type: 'folder',
            id: folder._id,
            updatedAt: createdAtFormat(folder.updatedAt),
            createdAt: createdAtFormat(folder.createdAt)
          }
        }).filter(f => f.role === 'main')
      } else {
        return this.activeFolderObject.subfolders
      }
    },
    documentsAndFolders () {
      return [...this.filteredFolders, ...this.filteredDocuments]
    },
    tableHeaders () {
      const headers = [
        {
          text: this.$t('common|type').charAt(0).toUpperCase() + this.$t('common|type').slice(1),
          align: 'start',
          class: 'mt-4',
          sortable: false,
          value: '_type',
          show: this.$vuetify && this.$vuetify.breakpoint.mobile
        },
        {
          text: this.$t('common|name'),
          align: 'start',
          class: 'mt-4',
          sortable: false,
          value: 'raw.originalname',
          show: true
        },
        {
          text: this.$t('documents|file_size'),
          align: 'center',
          class: 'mt-4',
          sortable: true,
          value: 'size',
          width: '120px',
          show: true
          // sort: (a, b) => {}
        },
        {
          text: this.$t('common|status'),
          align: 'center',
          class: 'mt-4',
          sortable: true,
          value: 'status',
          width: '80px',
          show: true
        },
        {
          text: this.$t('documents|document_type'),
          align: 'center',
          class: 'mt-4',
          width: '180',
          sortable: true,
          value: 'documentType',
          show: true
        },
        {
          text: this.$t('clients|client'),
          align: 'center',
          class: 'mt-4',
          sortable: true,
          value: 'clientName',
          show: true
        },
        {
          text: this.$t('cases|cases'),
          align: 'center',
          class: 'mt-4',
          sortable: true,
          value: 'cases',
          show: true
        },
        {
          text: this.$t('common|date_created'),
          align: 'center',
          class: 'mt-4',
          sortable: true,
          value: 'createdAt',
          width: '100',
          show: true
        },
        {
          text: this.$t('actions|actions'),
          value: 'actions',
          class: 'mt-4',
          sortable: false,
          align: 'center',
          width: '300px',
          show: true
        }
      ]
      return headers.filter(item => item.show)
    }
    // rightClickMenuItems () {
    //   if (this.actualRightClickedRow && this.actualRightClickedRow.collectionType === 'Folders') {
    //     return [
    //       {
    //         text: this.$t('actions|open'),
    //         icon: this.icons.openFolder,
    //         action: 'open-folder'
    //       },
    //       {
    //         text: 'Change folder name',
    //         icon: this.icons.editFolder,
    //         action: 'change-folder-name'
    //       },
    //       {
    //         text: 'Share folder',
    //         icon: this.icons.share,
    //         action: 'share-folder'
    //       },
    //       {
    //         text: 'Export folder',
    //         icon: this.icons.exportFolder,
    //         action: 'export-folder'
    //       },
    //       {
    //         text: 'Delete folder',
    //         icon: 'mdi-trash-can',
    //         action: 'delete-folder'
    //       }
    //     ]
    //   }
    //   if (this.actualRightClickedRow) {
    //     return [
    //       {
    //         text: this.$t('actions|open'),
    //         icon: 'mdi-file-eye',
    //         action: 'open-doc'
    //       },
    //       {
    //         text: 'Move to',
    //         icon: this.icons.moveFolder,
    //         action: 'move-doc-to'
    //       },
    //       {
    //         text: this.$t('actions|change_document_name'),
    //         icon: 'mdi-pencil',
    //         action: 'change-name'
    //       },
    //       {
    //         text: this.$t('actions|export'),
    //         icon: 'mdi-export',
    //         action: 'export-doc'
    //       },
    //       {
    //         text: this.$t('actions|share'),
    //         icon: 'mdi-share-variant',
    //         action: 'share-doc'
    //       },
    //       {
    //         text: this.$t('actions|assign'),
    //         icon: 'mdi-paperclip',
    //         action: 'assign-doc'
    //       },
    //       {
    //         text: this.$t('actions|delete'),
    //         icon: 'mdi-delete',
    //         action: 'delete-doc'
    //       }
    //     ]
    //   } else {
    //     return [
    //       {
    //         text: 'Create folder',
    //         icon: 'mdi-folder-plus-outline',
    //         action: 'create-folder'
    //       }
    //     ]
    //   }
    // }
  },
  watch: {
    // filteredFolders: {
    //   handler (oldVal, newVal) {
    //   },
    //   deep: true
    // },
    tabChanged (val) {
      if (val && this.$route.path.includes('folders')) this.$router.push('/documents')
    },
    folders: {
      handler (oldVal, newVal) {
        if (newVal) {
          this.refreshDocumentsKey++
        }
      },
      deep: true
    },
    documents: {
      handler (oldVal, newVal) {
        if (newVal) {
          this.refreshDocumentsKey++
        }
      },
      deep: true
    },
    companyDocuments: {
      handler (oldVal, newVal) {
        if (newVal) {
          this.refreshDocumentsKey++
        }
      },
      deep: true
    },
    groupDocuments: {
      handler (oldVal, newVal) {
        if (newVal) {
          this.refreshDocumentsKey++
        }
      },
      deep: true
    }
    // showRightClickMenu (val) {
    //   if (!val) {
    //     this.menuSelection = null
    //     this.actualRightClickedRow = null
    //   }
    // }
  },
  mounted () {
    document.oncontextmenu = () => {
      return false
    }
    addEventListener('contextmenu', (event) => {
      if ('ontouchstart' in window) {
        // This device supports touch events, so it's likely a mobile device
        // Handle your mobile context menu logic here
        console.log('Mobile context menu opened')
      } else {
        let item = null
        this.contextMenuId = event.target.id
        this.showRightClickMenu = true
        this.menuPositionX = event.pageX
        this.menuPositionY = event.pageY
        if (this.activeFolder) {
          for (let i = 0; i < this.folders.length; i++) {
            const folder = this.folders[i]
            item = folder.documents.find(doc => doc._id === event.target.id)
            if (item) break
          }
          if (!item) {
            const isFolder = this.activeFolderObject.subfolders.find(af => af._id === event.target.id)
            item = isFolder
          }
        } else {
          item = this.documents.find(doc => doc._id === event.target.id) ||
        this.companyDocuments.find(cd => cd._id === event.target.id) ||
        this.groupDocuments.find(gd => gd._id === event.target.id)

          if (!item) {
            item = this.folders.find(fol => fol._id === event.target.id) ||
          this.companyFolders.find(cfol => cfol._id === event.target.id) ||
          this.groupFolders.find(gfol => gfol._id === event.target.id)
          }
        }
        this.actualRightClickedRow = item
        const info = {
          actualRightClickedRow: item,
          showRightClickMenu: true,
          menuPositionX: event.pageX,
          menuPositionY: event.pageY
        }
        this.$emit('right-click', info)
      }
    })
  },
  updated () {
    const lang = localStorage.getItem('preferedLanguage') || 'en'
    if (lang) {
      this.selectedLanguage = lang
    }
  },
  created () {
    const lang = localStorage.getItem('preferedLanguage') || 'en'
    if (lang) {
      this.selectedLanguage = lang
    }
  },
  beforeDestroy () {
    document.oncontextmenu = function () { return true }
  }
  // ALL METHODS IN DOCUMENT MIXIN AVAILABLE
}
</script>

<style lang="scss" scoped>
  .input:focus {
    outline: none !important;
    border:1px solid #234e79;
  }
  .bottom-element-documents-mobile {
    position: fixed;
    bottom: 0px;
    width: 100%;
  }
  .documents-table-wrapper {
    width: 100%;
  }
  .active-folder {
    background-color: #ecb652;
  }
  // .ghost:nth-child(1),
  // .ghost:nth-child(n+3) {
  //  display: none;
  // }
  // .ghost {
  //   width: 50%;
  //   display: flex;
  //   flex-direction: column;
  //   border: 1px red solid;
  // }
  .ghost :nth-child(n+3) {
    display: none;
  }

</style>

<style lang="scss">
  .v-data-table-header th {
    white-space: nowrap;
  }
  .v-data-table-header {
    height: 45px;
  }
  .v-data-table tr:hover {
    background-color: #F6F4F4 !important;
    & .input-label-doc-table {
      position: absolute;
      left: 20px;
      top: -8px;
      padding-left: 5px;
      padding-right: 5px;
      padding-top: 0px;
      padding-bottom: 0px;
      white-space: nowrap;
      color: #234e79;
      background-color: #F6F4F4;
      font-size: 9px;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 120px;
    }
  }
  .input-label-doc-table {
    position: absolute;
    left: 20px;
    top: -8px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
    white-space: nowrap;
    color: #234e79;
    background-color: white;
    font-size: 9px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 120px;
  }

  // comment this out to fix the placeholder opacity in Firefox
  ::-moz-placeholder {
    opacity: 1;
  }
</style>
