<template>
  <v-container>
    <v-card>
      <v-toolbar
        dense
        flat
      >
        <v-icon
          @click="$emit('close')"
        >
          mdi-arrow-left
        </v-icon>

        <v-toolbar-title
          class="ml-2"
        >
          {{ this.$t('documents|name') }}
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-text-field
          v-model="newName"
          :value="originalName"
          clearable
          outlined
          dense
          :label="$t('actions|add_new_document_name')"
          class="mt-2"
        />
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          text
          color="primary"
          class="mb-2"
          @click="$emit('update-data', item, newName)"
        >
          {{ this.$t('actions|submit') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>

export default {
  props: {
    item: {
      type: Object,
      default: null
    },
    originalName: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      newName: this.originalName
    }
  }
}
</script>
