<template>
  <v-dialog
    v-model="dialog"
    max-width="700"
    transition="dialog-bottom-transition"
  >
    <v-card
      height="600"
    >
      <v-toolbar
        flat
        class="pa-2"
      >
        <v-toolbar-title
          v-if="dialog && doc"
        >
          <v-icon
            v-if="isIcon(doc.raw.mimetype, doc.raw.originalname)"
            :id="doc._id"
            :color="getItemColor(doc.raw.mimetype)"
            class="mr-2"
          >
            {{ getIconType(doc.raw.mimetype, doc.raw.originalname) }}
          </v-icon>
          {{ doc.raw.originalname }}
        </v-toolbar-title>
        <v-spacer />
        <v-icon
          class="mt-n3"
          @click="dialog = false"
        >
          mdi-close
        </v-icon>
      </v-toolbar>
      <v-toolbar
        flat
        color="lightBackground"
        class="pa-2"
      >
        <v-toolbar-title>
          <DocumentsTableNav
            :route-elements="routeElements"
            :container-id="'NAV-MOVE-DOC'"
            :active-folder-object="activeFolder"
            @router-action="onRouterAction"
          />
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text
        class="mt-6"
      >
        <v-list
          class="rounded-lg"
          :style="`height: 380px; overflow-y: auto; background-color: ${$vuetify.theme.themes.light.lightBackground}`"
        >
          <v-list-item
            v-for="fol in filteredFolders"
            :key="fol._id"
            @click="onRouterAction(fol)"
          >
            <v-icon class="mr-2">
              mdi-folder-outline
            </v-icon>
            {{ fol.raw.originalname }}
          </v-list-item>

          <v-list-item
            v-for="(_doc, i) in filteredDocuments"
            :key="i"
            disabled
          >
            <v-icon
              v-if="isIcon(_doc.raw.mimetype, _doc.raw.originalname)"
              :id="_doc._id"
              disabled
              :color="getItemColor(_doc.raw.mimetype)"
              class="mr-2"
            >
              {{ getIconType(_doc.raw.mimetype, _doc.raw.originalname) }}
            </v-icon>
            {{ _doc.raw.originalname }}
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          outlined
          rounded
          color="primary"
          @click="moveDocumentHere(doc, activeFolder, actualTab)"
        >
          Move document here
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import DocumentMixin from '../../pages/Documents/DocumentMixins/DocumentMixin'
import DocumentsTableNav from '../../pages/Documents/DocumentsTableNav.vue'

export default {
  components: { DocumentsTableNav },
  mixins: [DocumentMixin],
  props: {
    actualTab: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      dialog: false,
      doc: null,
      activeFolder: null
      // availableFolders: []
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      documents: state => state.documents.documents,
      sharedWithMeDocuments: state => state.documents.sharedWithMeDocuments,
      companyDocuments: state => state.companyDocuments.companyDocuments,
      groupDocuments: state => state.groupDocuments.groupDocuments,
      folders: state => state.documents.folders,
      companyFolders: state => state.companyDocuments.companyFolders,
      groupFolders: state => state.groupDocuments.groupFolders
    }),
    filteredFolders () {
      if (this.activeFolder) return this.activeFolder.subfolders
      else return this.folders.filter(f => f.role === 'main')
    },
    filteredDocuments () {
      if (this.activeFolder) return this.activeFolder.documents
      else return this.documents
    },
    routeElements () {
      let elements = [{ text: 'Main', _id: null }]
      if (this.activeFolder) {
        elements = []
        elements.push({ text: this.activeFolder.raw.originalname, _id: this.activeFolder._id })
        const getAllParentFolders = (folId) => {
          if (folId) {
            const foundParentFolder = this.folders.find(f => f._id === folId)
            elements.push({ text: foundParentFolder.raw.originalname, _id: foundParentFolder._id })
            getAllParentFolders(foundParentFolder.belongsTo)
          }
        }
        getAllParentFolders(this.activeFolder.belongsTo)
      }
      if (this.activeFolder) elements.push({ text: 'Main', _id: null })
      return elements.reverse()
    }
  },
  watch: {
    dialog (val) {
      if (!val) this.resetDialog()
    }
  },
  methods: {
    onRouterAction (folder) {
      if (folder.text === 'Main' && folder._id === null) {
        this.activeFolder = null
      }
      const newActiveFolder =
      this.folders.find(af => af._id === folder._id) ||
      this.companyFolders.find(cf => cf._id === folder._id) ||
      this.groupFolders.find(gf => gf._id === folder._id)
      if (newActiveFolder) this.activeFolder = newActiveFolder
    },
    resetDialog () {
      this.doc = null
      this.activeFolder = null
      // this.availableFolders = []
    }
  }
}

</script>
