<template>
  <v-dialog
    v-if="dialog"
    v-model="dialog"
    keydown
    max-width="900px"
    transition="dialog-bottom-transition"
    @keydown.esc="close()"
    @click:outside="close()"
  >
    <v-card
      color="lightBackground"
      class="px-auto"
    >
      <div style="position: absolute; right: 10px; top: 5px;">
        <v-btn
          icon
          @click="close()"
          @mouseover="hoverCloseButtonIcon = true"
          @mouseleave="hoverCloseButtonIcon = false"
        >
          <v-icon
            v-if="hoverCloseButtonIcon"
            small
          >
            mdi-close
          </v-icon>
          <v-icon
            v-else
          >
            mdi-close
          </v-icon>
        </v-btn>
      </div>
      <div
        v-if="folder && Object.keys(folder).length"
        class="pt-5"
      >
        <v-container
          fill-height
          fluid
        >
          <v-col
            cols="12"
          >
            <v-expand-transition>
              <v-card v-show="showPersonal">
                <v-toolbar
                  flat
                  width="100%"
                  :rounded="false"
                >
                  <v-icon
                    color="primary"
                  >
                    mdi-account-plus
                  </v-icon>
                  <v-toolbar-title
                    v-if="folder.raw && folder.raw.originalname"
                    class="ml-2"
                  >
                    {{ $t('actions|share') }} {{ folder.raw.originalname }} {{ $t('common|with_people') }}
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-text-field
                    v-model="userEmail"
                    :label="$t('actions|enter_e_mail_here')"
                    :hint="$t('expressions|email_of_the_recipient')"
                    persistent-hint
                    :error-messages="emailErrors"
                    class="purple-input"
                    outlined
                    required
                    dense
                    append-icon="mdi-close"
                    @click:append="clearField()"
                    @input="$v.userEmail.$touch()"
                    @blur="$v.userEmail.$touch()"
                  />
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    v-if="userEmail.length > 0 && !emailErrors.length"
                    class="mx-0 font-weight-light"
                    color="primary"
                    small
                    @click="shareFolderWithSingleAcc(userEmail)"
                  >
                    {{ $t('actions|share') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-expand-transition>
          </v-col>
          <v-col
            v-if="company"
            cols="12"
          >
            <v-expand-transition>
              <v-card
                v-show="showCompany"
                v-if="company"
              >
                <v-toolbar
                  flat
                  width="100%"
                  :rounded="false"
                >
                  <v-icon
                    color="primary"
                  >
                    mdi-account-multiple-plus
                  </v-icon>
                  <v-toolbar-title
                    v-if="folder.raw && folder.raw.originalname"
                    class="ml-2"
                  >
                    {{ $t('actions|share') }} {{ folder.raw.originalname }} {{ $t('common|with_company_or_group') }}
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-select
                    v-model="selectedItem"
                    :items="items"
                    outlined
                    :label="$t('actions|select')"
                    dense
                    :clearable="true"
                    class="mt-2"
                  />
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    v-if="selectedItem"
                    :loading="loading"
                    class="mx-0 font-weight-light"
                    color="primary"
                    small
                    @click="shareFolder(selectedItem)"
                  >
                    {{ $t('actions|share') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-expand-transition>
          </v-col>
          <v-col
            cols="12"
          >
            <v-expand-transition>
              <v-card
                v-if="company && userRole && userRole === 'regular' && !userEmail.length && !selectedItem"
                disabled
              >
                <v-toolbar
                  flat
                  width="100%"
                  :rounded="false"
                >
                  <v-icon
                    color="grey"
                  >
                    mdi-link
                  </v-icon>
                  <v-toolbar-title
                    v-if="folder.raw && folder.raw.originalname"
                    class="ml-2"
                  >
                    {{ $t('actions|share') }} {{ folder.raw.originalname }} {{ $t('actions|with_everyone_via_link') }}
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-actions>
                  <v-row>
                    <v-col
                      :cols="generatedLink ? '12' : '10'"
                    >
                      <v-text-field
                        v-if="generatedLink"
                        v-model="generatedLink"
                        :append-icon="clipboardIcon"
                        filled
                        :label="$t('common|link')"
                        @click:append="copy"
                      />
                    </v-col>
                    <v-col
                      v-if="!generatedLink"
                      cols="2"
                    >
                      <v-btn
                        class="mr-2 my-2 font-weight-light"
                        color="primary"
                        disabled
                        :loading="loading"
                        x-small
                        @click="generateLink"
                      >
                        {{ $t('actions|get_link') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
              <v-card
                v-else
                v-show="!userEmail.length && !selectedItem"
              >
                <v-toolbar
                  flat
                  width="100%"
                  :rounded="false"
                >
                  <v-icon
                    color="primary"
                  >
                    mdi-link
                  </v-icon>
                  <v-toolbar-title
                    v-if="folder.raw && folder.raw.originalname"
                    class="ml-2"
                  >
                    {{ $t('actions|share') }} {{ folder.raw.originalname }} {{ $t('actions|with_everyone_via_link') }}
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-actions>
                  <v-row>
                    <v-col
                      :cols="generatedLink ? '12' : '10'"
                    >
                      <v-text-field
                        v-if="generatedLink"
                        v-model="generatedLink"
                        :append-icon="clipboardIcon"
                        filled
                        :label="$t('common|link')"
                        @click:append="copy"
                      />
                    <!-- <span>{{ generatedLink }}</span> -->
                    </v-col>
                    <v-col
                      v-if="!generatedLink"
                      cols="2"
                    >
                      <v-btn
                        class="ml-n4 font-weight-light"
                        color="primary"
                        x-small
                        :loading="loading"
                        @click="generateLink"
                      >
                        {{ $t('actions|get_link') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-expand-transition>
          </v-col>
        </v-container>
      </div>
      <div
        v-if="selectedDocuments && selectedDocuments.length"
        class="pt-5 pl-3"
      >
        <v-container>
          <v-row no-gutters>
            <v-col
              cols="4"
            >
              <v-card
                class="mx-auto mt-3 pa-2"
              >
                {{ $t('documents|documents') }}:
                <v-list
                  height="500"
                  style="overflow-y: auto;"
                >
                  <v-list-item
                    v-for="fol in selectedFolders"
                    :key="fol._id"
                  >
                    <v-icon
                      small
                      color="primary"
                      class="mr-2"
                    >
                      mdi-file
                    </v-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ fol.raw.originalname }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
            <v-col
              cols="8"
            >
              <v-col
                cols="12"
              >
                <v-expand-transition>
                  <v-card v-show="showPersonal">
                    <v-toolbar
                      flat
                      width="100%"
                      :rounded="false"
                    >
                      <v-icon
                        color="primary"
                      >
                        mdi-account-plus
                      </v-icon>
                      <v-toolbar-title
                        class="ml-2"
                      >
                        {{ $t('actions|share_with_other_lavvira_users') }}
                      </v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                      <v-text-field
                        v-model="userEmail"
                        :label="$t('actions|enter_e_mail_here')"
                        :hint="$t('expressions|email_of_the_recipient')"
                        persistent-hint
                        :error-messages="emailErrors"
                        class="purple-input"
                        outlined
                        required
                        dense
                        append-icon="mdi-close"
                        @click:append="clearField()"
                        @input="$v.userEmail.$touch()"
                        @blur="$v.userEmail.$touch()"
                      />
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        v-if="userEmail.length > 0 && !emailErrors.length"
                        class="mx-0 font-weight-light"
                        color="primary"
                        small
                        @click="shareMultipleDocWithSingleAcc(userEmail)"
                      >
                        {{ $t('actions|share') }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-expand-transition>
              </v-col>
              <v-col
                v-if="company"
                cols="12"
              >
                <v-expand-transition>
                  <v-card
                    v-show="showCompany"
                    :class="showPersonal ? '' : 'mt-n6'"
                  >
                    <v-toolbar
                      flat
                      width="100%"
                      :rounded="false"
                    >
                      <v-icon
                        color="primary"
                      >
                        mdi-account-multiple-plus
                      </v-icon>
                      <v-toolbar-title
                        class="ml-2"
                      >
                        {{ $t('actions|share_with_group') }}
                      </v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                      <v-select
                        v-model="selectedItem"
                        :items="items"
                        outlined
                        :label="$t('actions|select')"
                        dense
                        :clearable="true"
                        class="mt-2"
                      />
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        v-if="selectedItem"
                        :loading="loading"
                        class="mx-0 font-weight-light"
                        color="primary"
                        small
                        @click="shareMultipleDocs(selectedItem)"
                      >
                        {{ $t('actions|share') }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-expand-transition>
              </v-col>
              <v-col
                v-if="company && userRole && userRole === 'regular'"
                cols="12"
              >
                <v-expand-transition>
                  <v-card
                    v-show="!userEmail.length && !selectedItem"
                    disabled
                  >
                    <v-toolbar
                      flat
                      width="100%"
                      :rounded="false"
                    >
                      <v-icon
                        color="grey"
                      >
                        mdi-link
                      </v-icon>
                      <v-toolbar-title
                        class="ml-2"
                      >
                        {{ $t('actions|share_with_everyone_via_link') }}
                      </v-toolbar-title>
                    </v-toolbar>
                    <v-card-actions>
                      <v-row>
                        <v-col
                          cols="9"
                        >
                          <v-text-field
                            v-if="generatedLink"
                            v-model="generatedLink"
                            :append-icon="clipboardIcon"
                            filled
                            :label="$t('common|link')"
                            @click:append="copy"
                          />
                        </v-col>
                        <v-col
                          cols="3"
                        >
                          <v-btn
                            class="mr-2 my-2 font-weight-light"
                            color="primary"
                            :loading="loading"
                            disabled
                            x-small
                            @click="generateMultiLink()"
                          >
                            {{ $t('actions|get_link') }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-actions>
                  </v-card>
                </v-expand-transition>
              </v-col>
              <v-col
                v-else
                cols="12"
              >
                <v-expand-transition>
                  <v-card
                    v-show="!userEmail.length && !selectedItem"
                    :class="generatedLink ? 'mt-n12' : ''"
                  >
                    <v-toolbar
                      flat
                      width="100%"
                      :rounded="false"
                    >
                      <v-icon
                        color="primary"
                      >
                        mdi-link
                      </v-icon>
                      <v-toolbar-title
                        class="ml-2"
                      >
                        {{ $t('actions|share_with_everyone_via_link') }}
                      </v-toolbar-title>
                    </v-toolbar>
                    <v-card-actions>
                      <v-row>
                        <v-col
                          :cols="generatedLink ? '12' : '9'"
                        >
                          <v-text-field
                            v-if="generatedLink"
                            v-model="generatedLink"
                            :append-icon="clipboardIcon"
                            filled
                            :label="$t('common|link')"
                            @click:append="copy"
                          />
                        </v-col>
                        <v-col
                          v-if="!generatedLink"
                          cols="3"
                        >
                          <v-btn
                            class="ml-n4 my-2 font-weight-light"
                            color="primary"
                            x-small
                            :loading="loading"
                            @click="generateMultiLink()"
                          >
                            {{ $t('actions|get_link') }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-actions>
                  </v-card>
                </v-expand-transition>
              </v-col>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import JSZip from 'jszip'
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import { mdiClipboardPlusOutline } from '@mdi/js'

export default {
  components: { },
  mixins: [validationMixin],
  validations: {
    userEmail: { required, email }
  },
  data () {
    return {
      loading: false,
      actualTab: {},
      folderId: null,
      document: {},
      documentName: '',
      selectedDocuments: [],
      dialog: false,
      userRole: '',
      userEmail: '',
      selectedItem: '',
      hoverCloseButtonIcon: false,
      clipboardIcon: mdiClipboardPlusOutline,
      generatedLink: '',
      showCompany: true,
      showPersonal: true,
      folder: null,
      activeFolder: null,
      folders: []
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      company: state => state.company.company,
      documents: state => state.documents.documents,
      sharedWithMeDocuments: state => state.documents.sharedWithMeDocuments,
      companyDocuments: state => state.companyDocuments.companyDocuments,
      groupDocuments: state => state.groupDocuments.groupDocuments
    }),
    emailErrors () {
      const errors = []
      if (this.userEmail !== null && this.userEmail.length > 0) {
        if (!this.$v.userEmail.$dirty) return errors
        !this.$v.userEmail.email && errors.push(this.$t('warning|valid_email'))
        !this.$v.userEmail.required && errors.push(this.$t('warning|email_required'))
      }
      return errors
    },
    items () {
      let itemsArray = []
      if (this.actualTab.isCompanyTab) {
        for (let i = 0; i < this.company.groups.length; i++) {
          const group = this.company.groups[i]
          const memberShipInGroupIndex = group.groupMembers.findIndex(gm => gm._id === this.account._id)
          if (memberShipInGroupIndex !== -1) {
            itemsArray.push(group.groupName)
          }
        }
      } else {
        itemsArray.push(this.company.companyData.companyName)
        for (let i = 0; i < this.company.groups.length; i++) {
          const group = this.company.groups[i]
          const memberShipInGroupIndex = group.groupMembers.findIndex(gm => gm._id === this.account._id)
          if (memberShipInGroupIndex !== -1) {
            itemsArray.push(group.groupName)
          }
        }
      }
      return itemsArray
    }
  },
  watch: {
    userEmail (value) {
      if (value.length > 0) {
        this.showCompany = false
      } else {
        this.showCompany = true
      }
    },
    selectedItem (value) {
      if (value) {
        this.showPersonal = false
      } else {
        this.showPersonal = true
      }
    },
    generatedLink (value) {
      if (value) {
        this.showCompany = false
        this.showPersonal = false
      } else {
        this.showCompany = true
        this.showPersonal = true
      }
    }
  },
  methods: {
    ...mapActions({
      addToast: 'toasts/addToast',
      updateFolder: 'documents/updateFolder',
      updateCompanyDocument: 'companyDocuments/updateCompanyDocument',
      updateGroupDocument: 'groupDocuments/updateGroupDocument'
    }),
    clearField () {
      this.userEmail = ''
    },
    validate () {
      this.$v.$touch()
      if (this.$v.$error) return false
      return true
    },
    onAddShareEvent (document, tab) {
      this.documentId = document._id
      this.document = document
      this.documentName = document.raw.originalname
      this.actualTab = tab
      this.userRole = tab.userRole
      this.dialog = true
    },
    onAddMultiShareEvent (selectedDocs, tab) {
      this.selectedDocuments = selectedDocs
      this.actualTab = tab
      this.userRole = tab.userRole
      this.dialog = true
    },
    async generateLink () {
      this.loading = true
      let zipNameInput = 'lavvira-zip'
      let zip = new JSZip()
      let _subfolder
      let path
      let file
      const mainFolder = zip.folder(this.folder.raw.originalname)
      if (this.actualTab && this.actualTab.isMyTab && this.folder) {
        for (let i = 0; i < this.folder.documents.length; i++) {
          path = `/downloadDocument/${typeof this.folder.documents[i].createdBy === 'object' ? this.folder.documents[i].createdBy._id : this.folder.documents[i].createdBy}/${this.folder.documents[i].raw.filename}`
          file = await this.downloadDocument(path)
          mainFolder.file(this.folder.documents[i].raw.originalname, new Blob([file]))
        }
      } else {
        for (let i = 0; i < this.folder.documents.length; i++) {
          path = `/downloadDocument/${this.folder.documents[i].createdBy._id}/${this.folder.documents[i].raw.filename}`
          file = await this.downloadDocument(path)
          mainFolder.file(this.folder.documents[i].raw.originalname, new Blob([file]))
        }
      }
      const getFolderZipped = async (folder, parentFolder) => {
        _subfolder = parentFolder.folder(folder.raw.originalname)
        for (let i = 0; i < folder.documents.length; i++) {
          if (typeof folder.documents[i] === 'string') {
            const doc = this.documents.find(d => d._id === folder.documents[i]) ||
            this.companyDocuments.find(d => d._id === folder.documents[i]) ||
            this.groupDocuments.find(d => d._id === folder.documents[i]) ||
            this.sharedWithMeDocuments.find(d => d._id === folder.documents[i])
            path = `/downloadDocument/${typeof doc.createdBy === 'object' ? doc.createdBy._id : doc.createdBy}/${doc.raw.filename}`
            file = await this.downloadDocument(path)
            _subfolder.file(folder.documents[i].raw.originalname, new Blob([file]))
          } else if (typeof folder.documents[i] === 'object') {
            path = `/downloadDocument/${typeof folder.documents[i].createdBy === 'object' ? folder.documents[i].createdBy._id : folder.documents[i].createdBy}/${folder.documents[i].raw.filename}`
            file = await this.downloadDocument(path)
            _subfolder.file(folder.documents[i].raw.originalname, new Blob([file]))
          }
        }
        return _subfolder
      }
      const handleSubFolders = async (subfolders, parentFolder) => {
        let zippedFolder
        for (let idx = 0; idx < subfolders.length; idx++) {
          let subfolder
          if (typeof subfolders[idx] === 'object') {
            subfolder = subfolders[idx]
          } else if (typeof subfolders[idx] === 'string') {
            subfolder = this.folders.find(sf => sf._id === subfolders[idx])
          }
          zippedFolder = await getFolderZipped(subfolder, parentFolder)
          if (subfolder.subfolders && subfolders.length) {
            handleSubFolders(subfolder.subfolders, zippedFolder)
          }
        }
      }
      await handleSubFolders(this.folder.subfolders, mainFolder)
      const _href = await zip.generateAsync({ type: 'blob' })
        .then(function (content) {
          // see FileSaver.js
          const a = document.createElement('a')
          const href = window.URL.createObjectURL(content)
          a.href = href
          a.download = zipNameInput
          document.body.appendChild(a)
          return href
        }).catch((err) => {
          console.error('createZip', err)
        })
      this.generatedLink = _href
      this.loading = false
    },
    async generateMultiLink () {
      this.loading = true
      let mainFolder = null
      let zipNameInput = 'lavvira-zip'
      let zip = new JSZip()
      //  let docs = zip.folder('documents')
      let path
      let file
      if (this.actualTab && this.actualTab.isMyTab && this.folder) {
        mainFolder = zip.folder(this.folder.raw.originalname)
        for (let i = 0; i < this.folder.documents.length; i++) {
          path = `/downloadDocument/${typeof this.folder.documents[i].createdBy === 'object' ? this.folder.documents[i].createdBy._id : this.folder.documents[i].createdBy}/${this.folder.documents[i].raw.filename}`
          file = await this.downloadDocument(path)
          mainFolder.file(this.folder.documents[i].raw.originalname, new Blob([file]))
        }
      } else {
        for (let i = 0; i < this.folder.documents.length; i++) {
          path = `/downloadDocument/${this.folder.documents[i].createdBy._id}/${this.folder.documents[i].raw.filename}`
          file = await this.downloadDocument(path)
          mainFolder.file(this.folder.documents[i].raw.originalname, new Blob([file]))
        }
      }
      const _href = await zip.generateAsync({ type: 'blob' })
        .then(function (content) {
          // see FileSaver.js
          const a = document.createElement('a')
          const href = window.URL.createObjectURL(content)
          a.href = href
          a.download = zipNameInput
          document.body.appendChild(a)
          return href
        }).catch((err) => {
          console.error('createZip', err)
        })
      this.generatedLink = _href
      this.loading = false
    },
    async downloadDocument (path) {
      const { data } = await this.$axios({
        url: path,
        method: 'GET',
        responseType: 'blob' // important
      })
      return data
    },
    copy () {
      navigator.clipboard.writeText(this.generatedLink)
      this.addToast({
        title: this.$t('message|link_to_clipboard'),
        color: 'white',
        snackbarColor: 'success'
      })
    },
    close () {
      this.documentId = ''
      this.documentName = ''
      this.document = {}
      this.generatedLink = ''
      this.userEmail = ''
      this.selectedItem = ''
      this.selectedDocuments = []
      this.actualTab = {}
      this.userRole = ''
      this.hoverCloseButtonIcon = false
      this.loading = false
      this.dialog = false
    },
    async shareFolder (selected) {
      this.loading = true
      let payload
      if (this.company.companyData.companyName === selected) {
        payload = {
          sharedWith: this.company._id,
          type: 'Company',
          shareFolderInternal: true,
          _id: this.folderId
        }
      }
      if (this.company.companyData.companyName !== selected) {
        for (let i = 0; i < this.company.groups.length; i++) {
          const group = this.company.groups[i]
          if (group.groupName === selected) {
            payload = {
              sharedWith: group._id,
              type: 'Group',
              shareFolderInternal: true,
              _id: this.folderId
            }
          }
        }
      }
      try {
        await this.updateFolder({
          _id: this.actualTab.isMyTab
            ? this.account._id
            : this.actualTab.isCompanyTab
              ? this.company._id
              : this.actualTab.isGroupTab
                ? this.actualTab.group._id : null,
          payload
        })
      } catch (error) {
        console.error(error, 'error')
      } finally {
        this.loading = false
        this.close()
      }
    },
    async shareMultipleDocs (selected) {
      this.loading = true
      let payload = {}
      for (let i = 0; i < this.selectedDocuments.length; i++) {
        const doc = this.selectedDocuments[i]
        if (this.company.companyData.companyName === selected) {
          payload = {
            sharedWith: this.company._id,
            type: 'Company'
          }
        }
        if (this.company.companyData.companyName !== selected) {
          for (let i = 0; i < this.company.groups.length; i++) {
            const group = this.company.groups[i]
            if (group.groupName === selected) {
              payload = {
                sharedWith: group._id,
                type: 'Group'
              }
            }
          }
        }
        try {
          if (this.actualTab.isMyTab && !this.actualTab.isCompanyTab && !this.actualTab.isGroupTab) {
            await this.updateDocument({
              _id: doc._id,
              payload
            })
          } else if (this.actualTab.isCompanyTab && !this.actualTab.isMyTab && !this.actualTab.isGroupTab) {
            await this.updateCompanyDocument({
              _id: doc._id,
              payload
            })
          } else if (this.actualTab.isGroupTab && !this.actualTab.isCompanyTab && !this.actualTab.isMyTab) {
            await this.updateGroupDocument({
              _id: doc._id,
              payload
            })
          }
        } catch (error) {
          console.error(error, 'err')
        }
      }
      this.loading = false
      this.close()
    },
    async shareFolderWithSingleAcc (mail) {
      let senderId = null
      const payload = {
        email: mail,
        shareFolderWithAccount: true,
        folderId: this.folder._id,
        actualTab: this.actualTab
      }
      if (
        this.actualTab.isMyTab &&
        !this.actualTab.isCompanyTab &&
        !this.actualTab.isGroupTab
      ) senderId = this.account._id
      if (
        this.actualTab.isCompanyTab &&
        !this.actualTab.isMyTab &&
        !this.actualTab.isGroupTab
      ) senderId = this.actualTab.company._id
      if (
        this.actualTab.isGroupTab &&
        !this.actualTab.isCompanyTab &&
        !this.actualTab.isMyTab
      ) senderId = this.actualTab.group._id
      try {
        await this.updateFolder({
          _id: senderId,
          payload
        })
      } catch (error) {
        console.error(error, 'Error in share folder')
      } finally {
        this.close()
      }
    },
    async shareMultipleDocWithSingleAcc (mail) {
      for (let i = 0; i < this.selectedDocuments.length; i++) {
        const doc = this.selectedDocuments[i]
        const payload = {
          email: mail
        }
        try {
          if (this.actualTab.isMyTab && !this.actualTab.isCompanyTab && !this.actualTab.isGroupTab) {
            await this.updateDocument({
              _id: doc._id,
              payload
            })
          } else if (this.actualTab.isCompanyTab && !this.actualTab.isMyTab && !this.actualTab.isGroupTab) {
            await this.updateCompanyDocument({
              _id: doc._id,
              payload
            })
          } else if (this.actualTab.isGroupTab && !this.actualTab.isCompanyTab && !this.actualTab.isMyTab) {
            await this.updateGroupDocument({
              _id: doc._id,
              payload
            })
          }
        } catch (error) {
          console.error(error, 'err')
        }
      }
      this.close()
    }
  }
}
</script>

<style>
</style>
